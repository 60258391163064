<template>
	<b-card>
		<!-- <h1>Temporadas</h1> -->
		<div class="mb-2">
			<!-- <b-button variant="primary" @click="addNewSeason" :disabled="!canAddNew">
				<feather-icon icon="PlusIcon" />
				Agregar Temporada
			</b-button> -->
		</div>
		<div>
			<b-table-simple>
				<b-thead class="text-center">
					<b-tr>
						<b-th>Periodo</b-th>
						<b-th>Rango</b-th>
						<b-th>Estado</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(season, i) in seasonList" :key="season.period">
						<b-td class="" width="440px">
							<div class="d-flex align-items-center justify-content-center">
								<h4 class="mb-0">{{ season.period }}</h4>
							</div>
						</b-td>
						<b-td class="text-center" width="500px">
							<div class="d-flex align-items-center justify-content-center">
								<div class="d-flex align-items-center justify-content-center">
									<b-form-group label="Inicio" class="mx-1">
										<flat-pickr
											v-model="season.range.start"
											class="form-control"
											placeholder="Seleccionar fechas"
											disabled
										/>
									</b-form-group>
									<b-form-group label="Final" class="mx-1">
										<flat-pickr
											v-model="season.range.end"
											class="form-control"
											:class="i != 0 ? '' : 'bg-white'"
											placeholder="Seleccionar fechas"
											:disabled="i != 0"
											:config="{ minDate: i == 0 ? dateIn : null }"
										/>
									</b-form-group>
								</div>
								<FeatherIcon
									v-if="i == 0"
									icon="SaveIcon"
									size="20"
									class="cursor-pointer text-primary"
									@click="updateEndDateById(season.id, season.range.end)"
								/>
							</div>
						</b-td>
						<b-td>
							<b-badge :variant="season.status == 'En curso' ? 'success' : 'danger'">{{
								season.status
							}}</b-badge>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
	</b-card>
</template>

<script>
import seasonService from "@/services/season.service";
import moment from "moment";

export default {
	data: () => ({
		seasonList: [
			// {
			// 	period: "base",
			// 	range: {
			// 		start: moment().add(-1, "years").toDate(),
			// 		end: moment().add(-10, "days").toDate(),
			// 	},
			// 	status: "in course",
			// },
		],
		dateIn: moment().format("YYYY-MM-DD"),
	}),
	computed: {
		canAddNew() {
			return !!this.seasonList[0].range.end;
		},
	},
	async mounted() {
		await this.init();
	},
	methods: {
		addNewSeason() {
			let previous = this.seasonList[0];
			let newSeason = {
				period: "new",
				range: {
					// start: moment(previous.range.end).add(1, "days").toDate(),
					start: this.plusDay(previous.range.end),
					end: null,
				},
				status: "in course",
			};

			this.seasonList.unshift(newSeason);
		},
		plusDay(date) {
			return moment(date).add(1, "days").toDate();
		},
		setEnd(ev) {
			let val = new Date(ev);
			this.seasonList[0].range.end = val;
		},
		async init() {
			const { data } = await seasonService.index();
			this.seasonList = data.map((s, index) => ({
				period: s.season,
				range: {
					start: s.start,
					end: s.end,
				},
				status: index == 0 ? "En curso" : "Finalizado",
				id: s.id,
			}));
		},
		async updateEndDateById(id, end_date) {
			const { message, status } = await seasonService.update({ id, end_date });
			if (status) {
				this.$store.dispatch({ type: "utilities/getPeriods" });

				this.showToast("success", "top-right", "Temporadas", "SuccessIcon", message);
			}
		},
	},
};
</script>
