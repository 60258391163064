import axios from "@/axios";

const url = "/api/season";

class SeasonService {
	async index() {
		try {
			const { data } = await axios.get(`${url}`);
			return data;
		} catch (err) {
			console.error(err);
		}
	}

	async update({id, end_date}) {
		try {
			const { data } = await axios.put(`${url}/update/${id}`, {
				end_date
			});
			return data;
		} catch (err) {
			console.error(err);
		}
	}
}
export default new SeasonService();
